<template>
  <div class="container page_question_card">
    <van-nav-bar
      :title="'识题卡 ' + (now_group + 1) + '/' + all_group + ' 组'"
      left-arrow
      class="nav_bar"
      @click-left="nav_back"
      @click-right="router_get_question_config"
    >
      <template #right>
        <van-icon name="setting-o" size="18" />
      </template>
    </van-nav-bar>

    <div class="card_box">
      <van-swipe class="my-swipe" :show-indicators="false">
        <van-swipe-item v-for="(item, idx) in questions" :key="idx">
          <div class="card_list">
            <div class="learing_state">
              <span class="not_learn" v-if="item.status == 0">未学</span>
              <span class="right" v-if="item.status == 1">答对</span>
              <span class="wrong" v-if="item.status == 2">答错</span>
            </div>
            <div class="question_type">
              <span class="tag">{{
                get_question_type_name(item.question_type)
              }}</span>
            </div>
            <div class="question_box_outter">
              <div class="question_box_outter_scroll">
                <div class="question_box">
                  <p>【题目】</p>
                  <div
                    class="question"
                    v-html="get_question_text(item.question)"
                  ></div>
                </div>
                <div class="question_box anser_box">
                  <p>【答案】</p>
                  <div class="question">
                    {{ item.answer_text }}
                  </div>
                </div>
              </div>
            </div>
            <div class="group_info">
              <img
                src="~@/assets/img/practice/fav_on.png"
                class="fav"
                v-if="item.is_favorited == 1"
                @click="cancel_fav(item.id, idx)"
              />
              <img
                src="~@/assets/img/practice/fav_off.png"
                class="fav"
                v-if="item.is_favorited == 0"
                @click="fav(item.id, idx)"
              />

              <img
                src="~@/assets/img/practice/wrong_report.png"
                class="wrong"
                @click="word_wrong_report(item.id)"
              />
              <div class="text">{{ idx + 1 }}/{{ questions.length }}</div>
            </div>
          </div>
        </van-swipe-item>
      </van-swipe>
    </div>

    <div
      class="start_btn"
      @click="router_practice()"
      v-if="questions.length > 0"
    >
      练习本组
    </div>
  </div>
</template>
<script>
import { NavBar, Swipe, SwipeItem, Icon, Toast, Dialog } from "vant";
import {
  get_set_questions,
  favorite_question,
  favorite_question_cancel,
  submit_question_wrong
} from "@/api/practice/api";
import { get_filter_item, arr_chunk, get_part_answer } from "@/utils/common";
import Cookies from "js-cookie";

export default {
  name: "QuestionCard",
  data() {
    return {
      part_id: 0,
      all_questions: [],
      questions: [],
      set_group_question_count: 10,
      now_group: "", //当前小组id
      all_group: ""
    };
  },
  watch: {
    $router: {
      handler: "req_get_set_questions", //调用方法
      immediate: true //进入立即执行一次
    }
  },
  methods: {
    nav_back() {
      this.$router.push({ path: "/unit?ts=" + Math.random() });
    },
    req_get_set_questions() {
      let id = this.$store.getters.set_id;
      if (isNaN(id) || id == "") {
        this.nav_back();
        return;
      }
      this.$store.commit("set_set_id", id);
      let is_unanswered = get_filter_item("is_unanswered");
      let is_right = get_filter_item("is_right");
      let is_favorited = get_filter_item("is_favorited");
      let is_wrong = get_filter_item("is_wrong");
      let is_get_new_set = get_filter_item("is_get_new_set");
      get_set_questions({
        set_id: id,
        is_get_new_set: is_get_new_set,
        is_unanswered: is_unanswered,
        is_right: is_right,
        is_favorited: is_favorited,
        is_wrong: is_wrong
      }).then(res => {
        if (res.code != 200) {
          Toast(res.info);
          return;
        }

        if (res.data.questions.length == 0) {
          Toast(res.info, 1000);
          setTimeout(() => {
            this.router_get_question_config();
          }, 1000);
          return;
        }

        Cookies.set("is_get_new_set", 0);

        //合并当前本地数据
        let now_user_answer = get_part_answer(res.data.id);
        this.all_questions = res.data.questions;

        for (let i = 0; i < this.all_questions.length; i++) {
          for (let m = 0; m < now_user_answer.length; m++) {
            if (this.all_questions[i]["id"] == now_user_answer[m]["id"]) {
              this.all_questions[i]["status"] = now_user_answer[m]["r"];
            }
          }
        }

        this.$store.commit("set_all_questions", this.all_questions);
        this.part_id = res.data.id;
        this.$store.commit("set_part_id", this.part_id);
        this.set_group_question_count = res.data.set_group_question_count;
        //设置当前小组
        this.set_now_group();
      });
    },
    //获取当前小组单词
    set_now_group() {
      let groups = [];
      groups = arr_chunk(this.all_questions, this.set_group_question_count);

      //检查当前组是否已经完成
      let now_group = -1;
      for (let i = 0; i < groups.length; i++) {
        let is_over = 1; //本组是否全部完成 1已经全部完成 0未全部完成
        for (let m = 0; m < groups[i].length; m++) {
          if (groups[i][m]["status"] == 0) {
            is_over = 0;
            break;
          }
        }

        if (is_over == 0) {
          now_group = i;
          break;
        }
      }

      console.log("总题目数" + this.all_questions.length);
      console.log("总小组数" + groups.length);
      this.questions = groups[now_group];
      this.now_group = now_group;
      this.all_group = groups.length;

      this.$store.commit("set_now_question", this.questions);
      this.$store.commit("set_now_group_idx", this.now_group);
      this.$store.commit("set_all_group", this.all_group);

      console.log(this.$store.getters);
    },
    //获取题目类型
    get_question_type_name(type) {
      if (type == 1) {
        return "单选题";
      }
      if (type == 2) {
        return "判断题";
      }
      return "";
    },
    //获取题目内容
    get_question_text(text) {
      text = text.replace(/\|DS_QS_SPACE\|/g, '<i class="blank"></i>');
      text = text.replace(/\n/g, "<br/>");
      return text;
    },
    //收藏
    fav(id, idx) {
      favorite_question({
        question_id: id
      }).then(res => {
        if (res.code != 200) {
          Toast(res.info);
          return;
        }
        this.questions[idx]["is_favorited"] = 1;
      });
    },
    //取消收藏
    cancel_fav(id, idx) {
      favorite_question_cancel({
        question_id: id
      }).then(res => {
        if (res.code != 200) {
          Toast(res.info);
          return;
        }
        this.questions[idx]["is_favorited"] = 0;
      });
    },
    //单词报错
    word_wrong_report(id) {
      Dialog.confirm({
        title: "题目报错",
        message: "感谢您帮助我们改进数据！\n是否确认本题题目有误或答案有误？"
      })
        .then(() => {
          submit_question_wrong({
            question_id: id
          }).then(res => {
            Toast(res.info);
          });
        })
        .catch(() => {});
    },
    //配置题目
    router_get_question_config() {
      this.$router.push({ path: "/get_question_config?ts=" + Math.random() });
    },
    //开始练习
    router_practice() {
      this.$router.push({ path: "/answer?ts=" + Math.random() });
    }
  },
  components: {
    [NavBar.name]: NavBar,
    [Swipe.name]: Swipe,
    [SwipeItem.name]: SwipeItem,
    [Icon.name]: Icon,
    [Toast.name]: Toast,
    [Dialog.Component.name]: Dialog.Component
  }
};
</script>
<style lang="stylus">
.page_question_card .blank{
    display inline-block
    width 80px
    height 24px
    border-bottom 1px solid #ccc
    margin 0 10px
    position relative
    top 3px
}
.page_question_card .nav_bar{
    background #fff
    border-bottom 0
}
.page_question_card .van-nav-bar__content{
    height: 54px;
}
.page_question_card .van-nav-bar__title{
    font-size: 18px;
    font-family: PingFangSC-Semibold, PingFang SC;
    font-weight: 600;
    color: #000;
}
.page_question_card .van-hairline--bottom::after{
    border none
}
.page_question_card .van-nav-bar .van-icon{
    color #000
    font-size 18px
}
.page_question_card .van-nav-bar .van-icon{
    font-size:22px !important
}
</style>

<style lang="stylus" scoped>
.container{
    width 100%
    height 100%
    background RGBA(248, 249, 251, 1)
    position relative
}
.card_box{
    padding-top: 70px;
    padding-bottom: 120px;
    height: 100%;
    box-sizing: border-box;
    position: absolute;
    z-index: 0;
    top: 0;
    left: 0;
    width: 100%;
    overflow: hidden;
}
.card_list{
    width 90%
    margin 0 auto
    height 100%
    background #fff
    border-radius 24px
    position relative
}
    .question_type{
        text-align center
        padding-top 32px
    }
        .tag{
            width: auto;
            height: 22px;
            background: RGBA(255, 222, 222, 1)
            font-weight 600
            border-radius: 6px;
            color rgba(255, 88, 89, 1)
            padding 2px 4px
            font-size 14px
        }
    .question_box_outter{
        width: 100%;
        height: 100%;
        overflow: hidden;
        box-sizing: border-box;
        padding-bottom: 107px;
        question-break: break-all;
        position: absolute;
        top: 0;
        padding-top: 85px;
    }
        .question_box_outter_scroll{
            width 100%
            height 100%
            overflow hidden
            overflow-y: scroll;
        }
    .question_box{
        padding 0 30px 0 30px
    }
        &>p{
            font-size 15px
            margin-bottom 10px
        }
        .question{
            font-size 14px
            line-height 24px
        }
    .anser_box{
      margin: 20px 0;
      padding-top: 20px;
      border-top: 1px solid #e6e5e5;
      width: 90%;
    }
.group_info{
    position: absolute;
    bottom: 39px;
    text-align: center;
    width: 100%;
}
.group_info .fav{
        position: absolute;
        left: 26px;
        top: -24px;
        width: 85px;
    }
.group_info .wrong{
        position: absolute;
        right: 44px;
        top: -9px;
        width: 45px;
    }
.group_info .text{
        font-size: 24px;
        font-family: Gotham-BoldItalic, Gotham;
        font-weight: normal;
        color: #2D395E;
        line-height: 29px;
    }
.start_btn{
    position: fixed;
    bottom: 20px;
    left: 5%;
    z-index: 1;
    width: 90%;
    height: 50px;
    background: linear-gradient(270deg, #FF5859 0%, #FF9A5F 100%);
    border-radius: 25px;
    margin 0 auto
    margin-top 40px
    color #fff
    text-align center
    line-height 50px
    font-size 16px
    font-weight 600
}
.van-swipe__track,.my-swipe{
    height 100%
}
.learing_state{
    text-align: right;
    position: absolute;
    top: 10px;
    right: 14px;
    width: 100%;
    height: 24px;
}
.learing_state>span{
    width: auto;
    height: 22px;
    background:#FFBC5F
    font-weight: 600;
    border-radius: 6px;
    color: #040404;
    padding: 2px 4px;
    font-size: 12px;
    margin: 0 4px;
}
.learing_state>span.learn{
  background RGBA(193, 235, 203, 1)
}
.learing_state>span.not_learn{
  background:#FFBC5F
}
.learing_state>span.right{
  background RGBA(193, 235, 203, 1)
}
.learing_state>span.wrong{
  background RGBA(255, 222, 222, 1)
}
</style>
